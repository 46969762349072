/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200;300;400;500;600&display=swap');
 
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Road";   /*Can be any text*/
  src: local("Road_Rage"),
    url("./fonts/Road_Rage.otf") format("opentype");
}

@layer base {
  html{
    font-family: 'Lato', sans-serif;
  }
}